<template>
    <v-container class="py-0">
        <v-card flat>
            <v-card-title class="pt-0">
                <v-spacer></v-spacer>
                 <v-text-field
                    v-model="search"
                    label="Type Student Name"
                    prepend-inner-icon="mdi-magnify"
                    single-line
                    clearable
                    outlined
                    dense
                    v-on:keyup="keyPress"
                >
                    <template v-slot:append>
                        <v-btn
                            depressed
                            @click="filterRecords"
                            small
                        >
                            Search Students
                        </v-btn>
                    </template>
                </v-text-field>
            </v-card-title>
        </v-card>
        <v-data-table
            :items="filterTermMarkRecords"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer 
        >
            <template v-slot:item="props">
                <Row
                    v-bind:studentRecord="props.item"
                    @update-filter="updateFilter"
                />
            </template>
        </v-data-table>
        <v-bottom-sheet
            v-model="presetCommentsSheet"
            inset
            persistent
        >
            <v-sheet
                class="pa-4"
                height="300px"
            >
                <v-card
                    flat
                >
                    <v-card-title>Select Comment</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 150px; overflow-y: scroll">
                        <v-list>
                            <v-list-item-group
                                v-model="selected"
                                active-class="green--text"
                                multiple
                            >
                                <template v-for="(comment, index) in presetCommentsList">
                                    <v-list-item :key="comment.id">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ comment.description }}
                                                </v-list-item-title>
                                                
                                            </v-list-item-content>
                                            <v-list-item-action>                                                
                                                <v-icon
                                                    v-if="!active"
                                                    color="grey lighten-1"
                                                >
                                                    mdi-star-outline
                                                </v-icon>
                                                <v-icon
                                                    v-else
                                                    color="yellow darken-3"
                                                >
                                                    mdi-star
                                                </v-icon>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < commentTemplates.length -1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import Row from './MarkSheetRow';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    components: {
        Row,
    },

    props: {
        selectedClasses: Array,
    },

    data: () =>({
        loading: false,
        height: "52vh",
        pagination: false,
        footer: false,                
        headers: [
            {text: '#', value: 'count', width: '60', sortable: false, filterable: false},
            {text: 'Class', value: 'form_class_id', align: 'center', width: '80', sortable: false,},
            {text: 'Name', value: 'name', sortable: false,},
            {text: 'Course', value: 'courseMark', align: 'center', width: '90', sortable: false, filterable: false},            
            {text: 'Exam', value: 'examMark', align: 'center', width: '90', sortable: false, filterable: false},            
            {text: 'Attendance', value: 'attendance', align: 'center', width: '100', sortable: false, filterable: false},
            {text: 'Comment', value: 'comment', align: 'center', width: '300', sortable: false, filterable: false},
            {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40', filterable: false},                        
        ],
        presetCommentsSheet: false,
        commentTemplates: [],
        selected: [], 
        search: '',     
        filterTermMarkRecords: [],
        termMarkRecordsCopy: [],
    }),    
    watch: {
        presetCommentsSheet: {
            handler(val){
                if(!val) this.setDisplayPresetComment(false);
            }
        },

        presetComments: {
            handler(val){
                this.presetCommentsSheet = val;
            }
        },

        selected: {
            handler(val){
                if(val.length > 0){
                    let commentsSelected = '';
                    val.forEach(comment => {
                        commentsSelected +=  this.presetCommentsList[comment].description + ' ';
                        commentsSelected = commentsSelected.substring(0,100);
                    })
                    this.termMarkRecordsCopy[this.selectedRecordIndex].comment = commentsSelected;
                    this.filterTermMarkRecords[this.selectedRecordIndex].comment = commentsSelected;

                }
                
            }
        } ,
        
        termMarkRecords: {
            handler(){
                this.initialize();              
            }
        },

        search: {
            handler(val){
                if(!val){
                    console.log('search cleared')
                    this.filterTermMarkRecords = [];
                    this.$nextTick(() => {
                        this.filterTermMarkRecords = this.termMarkRecords.map(obj => ({...obj}));
                    })
                } 
            }
        },

        selectedClasses: {
            handler(val){ 
                this.setFilterByClass(val);
            }    
        }
    }, 

    computed:{
        ...mapGetters({            
            termMarkRecords: 'markSheet/getTermMarkRecords',
            presetComments: 'markSheet/getDisplayPresetComment',
            presetCommentsList: 'markSheet/getPresetComments',
            selectedRecordIndex: 'markSheet/getSelectedRecordIndex',
            selectedTeacher: 'markSheet/getSelectedTeacher',
            academicTermId: 'app/getAcademicTermId',
            //studentSelected: 'markSheet/getSelectedStudentRecord',            
        }),

    },
    methods: { 
       ...mapMutations({
           setDisplayPresetComment: 'markSheet/setDisplayPresetComment',
           setTermMarkRecords: 'markSheet/setTermMarkRecords',
           //setCommentsSelected: 'enterMarks/setCommentsSelected',
       }),

       ...mapActions({
           getStudents: 'markSheet/getStudents',
       }),

       initialize () {
            console.log('initializing...');
            this.termMarkRecordsCopy = this.termMarkRecords.map(obj => ({...obj}));
            this.filterTermMarkRecords = this.termMarkRecordsCopy;
       },

       close(){
           this.selected = [];
           this.setDisplayPresetComment(false);
           //this.setSelectedComment();
       },
      
       setPresentComments(data){
           data.forEach( comment => {
               let template = {};
               if(comment.comment_type_id == 1) template.title = 'Behavioural'
               else template.title = 'Academic'
               template.id = comment.id;
               template.headline = comment.description
               this.commentTemplates.push(template);
           })
       },

        keyPress (e) {
            if(e.keyCode === 13){
                this.filterRecords();
            }
        },

       filterRecords (){
        const filterResult = this.termMarkRecordsCopy.filter( record => {
            return this.search != null &&  typeof this.search === 'string' && record.name.toString().toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) !== -1
        })

        this.filterTermMarkRecords = [];
        this.$nextTick(() => {
            this.filterTermMarkRecords = filterResult
        })
       
       },

       async updateFilter (){
        console.log('updating filter...');
        let employeeId = this.selectedTeacher ? this.selectedTeacher.id : sessionStorage.getItem('employeeId')
        // this.setTermMarkRecords([]);
        try {
            const { data: { data } } = await this.getStudents({
                academicTermId: this.academicTermId,
                employeeId: employeeId
            });

            const termMarkRecords = data.map((record, index) => {
                record.name = record.first_name + ' ' + record.last_name;
                record.count = index + 1;
                return record;
            });

            this.termMarkRecordsCopy = termMarkRecords.map(obj => ({...obj}));
            // this.filterTermMarkRecords = this.termMarkRecordsCopy;

        } catch (error) {
            error.response ? console.log(error.response) : console.log(error);
        }
       },

       setFilterByClass(filterClasses){
            const filterResult = this.termMarkRecordsCopy.filter( record => filterClasses.includes(record.form_class_id));

            this.filterTermMarkRecords = [];
            this.$nextTick(() => {
                this.filterTermMarkRecords = filterResult
            })
       }
    }
}
</script>

<style>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
    }
    th span{
        display: flex;
    }

    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgb(190 190 190);
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(odd){
        background-color: rgb(250, 250, 250);
    }

    .v-data-table > .v-data-table__wrapper > table{
        padding-right: 24px
    }
</style>