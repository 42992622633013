<template>
    <tr @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td>
          {{ markRecord.form_class_id }}
        </td>  

        <td class="pt-1">
            {{ markRecord.name }}
        </td>
        <td>
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100
                class="mt-2"                
                :disabled="assesmentCourse.disable"
                :filled="assesmentCourse.disable"                
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"                
            ></v-text-field>
            <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="setAttendance(assesmentCourse, true)"
                        block
                        height="20"
                        class="my-2"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                    >{{ assesmentCourse.button }}</v-btn>
                </template>
                <span>{{ assesmentCourse.tooltip }}</span>
            </v-tooltip>    
        </td>

        <td>
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100                
                class="mt-2"
                :disabled="assesmentExam.disable || courseWorkOnly"
                :filled="assesmentExam.disable || courseWorkOnly" 
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
            ></v-text-field>
             <v-tooltip 
                top               
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        @click="setAttendance(assesmentExam, true)"
                        block
                        height="20"
                        class="my-2"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        :disabled="courseWorkOnly"
                    >
                        {{ assesmentExam.button }}
                    </v-btn>
                </template>
                <span>{{ assesmentExam.tooltip }}</span>
             </v-tooltip>        
        </td>

        <td>

           <v-select
                v-model="markRecord.attendance"                
                :items="commentsConduct"
                item-value="id"
                outlined
                dense
                hide-details                
                class="mt-2" 
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
            <v-btn
               disabled
               block
               height="20"
               class="my-2"
               text
            > </v-btn>
        </td>

       
          
        <td class="subject-comments">
            <v-textarea
              v-model="comment"
              rows="2"
              maxlength="100"
              dense
              counter
              class="mb-2"
              @blur="recordChanged"              
            ></v-textarea>
             <v-btn
                text
                class="mt-2"
                style="font-size: 10px; color:green"
                height="20"
                @click="showPresetComments"
            >
                Preset Comment
            </v-btn>
        </td>

        <td>
            <v-tooltip 
                bottom                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="primary"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saveState.saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="saveState.error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-if="saveState.saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,        
    },    
    created() {
        this.initialize(); 
    },    
    watch: {        
        
        studentRecord: {
            deep: true,
            handler (value) {
                if(value.comment){                    
                    this.comment = value.comment;
                    this.markRecord.comment = value.comment;
                    this.recordChanged();                    
                    //console.log(this.comment); 
                }                
            }
        },
        comment: {
            handler (value) {
                if(value) this.markRecord.comment = value;                
            }
        }
        
    },       
    data() {
        return {                               
            markRecord: {},       
            commentsConduct: [
                { id: null, comment: null},
                { id: 'A', comment: 'Excellent' },
                { id: 'B', comment: 'Very Good' },
                { id: 'C', comment: 'Good' },
                { id: 'D', comment: 'Poor' },
                { id: 'E', comment: 'Unsatisfactory' },                
            ],
            comment:'',
            saveState: {
                saved: false,
                saving: false,
                error: false
            },
            
            presetCommentsSheet: false,
            assesmentCourse: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'course_mark',
                modelAttendance: 'course_attendance',
                test: 2,
            },
            assesmentExam: {
                attendance: 1,
                button: 'P',
                tooltip: 'Present',
                disable: false,
                modelAssesment: 'exam_mark',
                modelAttendance: 'exam_attendance',
                test: 1,
            },
                     
        }
    },
    computed: {
        ...mapGetters({            
            getUser: 'auth/getUser',
            lessonSelected: 'markSheet/getLessonSelected',
            presetComments: 'enterMarks/getPresetComments',
            commentsSelected: 'enterMarks/getCommentsSelected',
            termMarksRecords: 'markSheet/getTermMarkRecords',
            test: 'markSheet/getTest',
            presetComment: 'markSheet/getPresetComment',
            courseWorkOnly: 'termReports/getCourseWorkOnly',
            selectedTeacher: 'markSheet/getSelectedTeacher',   
        }),

        user () {
            return JSON.parse(this.getUser);
        }
                
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'markSheet/setUpdatedTermMarkRecord',
            setStudentSelected: 'markSheet/setStudentSelected',            
            setDisplayPresetComments: 'markSheet/setDisplayPresetComment',
            setSelectedRecordIndex: 'markSheet/setSelectedRecordIndex',
        }),

        ...mapActions({
            save: 'markSheet/save',
        }),

        initialize(){ 
            //console.log(this.studentRecord);
            this.markRecord = {...this.studentRecord};
            this.comment = this.markRecord.comment;
            this.initializeAttendance(this.assesmentCourse, this.studentRecord.course_attendance);
            this.initializeAttendance(this.assesmentExam, this.studentRecord.exam_attendance);           
            this.savedDataCheck();            
        },

        initializeAttendance(assesment, attendance){
            assesment.attendance = attendance;
            switch(attendance){
                case 1:                    
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';
                    assesment.disable = false;
                    break;
                case 2:                    
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    assesment.disable = true;
                    break;
                case 3:                    
                    assesment.button = 'NW';
                    assesment.tooltip = 'No Work';
                    assesment.disable = true;
                    break;
            }
        },

        async recordChanged(){
            let index = this.termMarksRecords.findIndex((record) => {
                return record.student_id == this.studentRecord.student_id
            })
            this.setSelectedRecordIndex(index);

            if(this.isEmpty()){
                return;
            }

            const fieldsToCheck = ['course_mark', 'exam_mark', 'comment', 'interest', 'attendance', 'course_attendance', 'exam_attendance'];

            const hasChanges = fieldsToCheck.some(field => this.markRecord[field] !== this.studentRecord[field]);

            console.log('mark record: ', this.markRecord);

            console.log('student record: ', this.studentRecord);
            
            console.log('has changes: ', hasChanges);
            
            if(!hasChanges) return;

            console.log('record changed...');
            
            this.setSaveState();

            let employeeID = this.user.employee_id;
            if(this.selectedTeacher){
                employeeID = this.selectedTeacher.id
            }

            this.setUpdatedRecord({
                "student_id" : this.markRecord.student_id,                    
                "academic_term_id" : this.markRecord.academic_term_id,
                "subject_id" : this.markRecord.subject_id,
                "course_mark" : this.markRecord.course_mark,
                "exam_mark" : this.markRecord.exam_mark,
                "course_attendance" : this.markRecord.course_attendance,
                "exam_attendance" : this.markRecord.exam_attendance,
                "attendance" : this.markRecord.attendance,
                "interest": this.markRecord.interest,
                "comment" : this.markRecord.comment,                
                "employee_id" : employeeID,                   
            });

            console.log('mark record', this.markRecord);

            try {
                await this.save();
                this.termMarksRecords[index] = this.markRecord;
                this.$emit('update-filter');
                this.setSaveState('saved');
                    
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.setSaveState('error');
            }
                          
        },

        setSaveState (state = 'saving'){
            Object.keys(this.saveState).forEach(key => {
                this.saveState[key] = false;
                if(key == state) this.saveState[key] = true;
            })
        },

        isEmpty(){   
            const blank = Object.keys(this.markRecord).some(key => {
                if(key == 'course_attendance' || key == 'exam_attendance'){
                    //change to attendance made
                    return this.markRecord[key] != this.studentRecord[key];
                }
                if( 
                    (
                        key == 'course_mark' ||
                        key == 'exam_mark' ||
                        key == 'comment' ||
                        key == 'interest' ||
                        key == 'attendance'
                    ) &&
                    this.markRecord[key]
                )
                return true;
            })
            
            return !blank;
        },
        
        savedDataCheck(){
            if(!this.isEmpty()) this.setSaveState('saved');
        },
        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);            
            this.setStudentSelected(this.markRecord.name);
        },        
        
        showPresetComments(){ 
            console.log('show preset comments');
            let index = this.termMarksRecords.findIndex((record) => {
                return record.student_id == this.studentRecord.student_id
            })
            this.setSelectedRecordIndex(index);          
            this.setDisplayPresetComments(true);
        },
        setAttendance(assesment, update = false){
            switch(assesment.attendance){
                case 1:
                    assesment.attendance = 2;
                    assesment.button = 'Abs';
                    assesment.tooltip = 'Absent';
                    this.markRecord[assesment.modelAssesment] = null;
                    this.markRecord[assesment.modelAttendance] = 2
                    assesment.disable = true;
                    break;
                case 2:
                    assesment.attendance = (assesment.test === 2) ? 3 : 1;
                    if(assesment.test === 2){
                        assesment.attendance = 3;
                        assesment.button = 'NW';
                        assesment.tooltip = 'No Work';
                        this.markRecord[assesment.modelAssesment] = null;
                        this.markRecord[assesment.modelAttendance] = 3
                        assesment.disable = true;
                    }
                    else{
                        assesment.attendance = 1;
                        assesment.button = 'P';
                        assesment.tooltip = 'Present';
                        this.markRecord[assesment.modelAttendance] = 1;
                        assesment.disable = false;
                    }
                    break;
                case 3:
                    assesment.attendance = 1;
                    assesment.button = 'P';
                    assesment.tooltip = 'Present';                    
                    this.markRecord[assesment.modelAttendance] = 1
                    assesment.disable = false;
                    break;
            }
            if(update) this.recordChanged();
        },              
    }
}
</script>

<style scoped>
    .subject-comments{
        position: relative;
    }
    .subject-comments button{
        position: absolute;
        bottom: 5px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    text-align: center;
    }
</style>

