import axios from 'axios'

export default {
    namespaced: true,

    state: {
        dashboard: true,
        lessons: false,
        exportData: false,
        reports: false,
        importStudents: false,
        registerStudents: false,
        modal: false,
        modalIcon: '',
        modalTitle: '',
        modalMessage: '',
        progressOverlay: false,
        editedLesson: {},
        academicTerm: null,
        academicYearId: null,
        lessonOverlay: true,
        postLesson: null,
        deleteLesson: null,
        teacherLessons: [],        
        formTeacherAssignment: {},
        formDeanAssignments: {},
        formClasses: [],
        subjects: [],              
        dialog: false,
        editedEmployee: {},                
    },

    getters: {
        getDashboard(state){
            return state.dashboard
        },
        getExportData(state){
            return state.exportData
        },
        getReports(state){
            return state.reports
        },
        getImportStudents(state){
            return state.importStudents
        },
        getRegisterStudents(state){
            return state.registerStudents
        },
        getModal(state){
            return state.modal
        },
        getModalIcon(state){
            return state.modalIcon
        },
        getModalTitle(state){
            return state.modalTitle
        },
        getModalMessage(state){
            return state.modalMessage
        },
        getProgressOverlay(state){
            return state.progressOverlay
        },
        getLessons(state){
            return state.lessons
        },
        getEditedLesson(state){
            return state.editedLesson
        },
        getAcademicTerm(state){
            return state.academicTerm
        },
        getLessonOverlay(state){
            return state.lessonOverlay
        },
        getPostLesson(state){
            return state.postLesson
        },
        getAcademicYearId(state){
            return state.academicYearId
        },
        getDeleteLesson(state){
            return state.deleteLesson
        },
        getTeacherLessons(state){
            return state.teacherLessons
        },        
        getFormTeacherAssignment(state){
            return state.formTeacherAssignment
        },
        getFormClasses(state){
            return state.formClasses
        },
        getSubjects(state){
            return state.subjects
        },
        getFormDeanAssignments(state){
            return state.formDeanAssignments
        },               
        getDialog(state){
            return state.dialog
        },
        getEditedEmployee(state){
            return state.editedEmployee
        }
    },

    mutations: {
        setDashboard(state, value){
            state.dashboard = value
        },
        setExportData(state, value){
            state.exportData = value;
        },
        setReports(state, value){
            state.reports = value
        },
        setImportStudents(state, value){
            state.importStudents = value
        },
        setRegisterStudents(state, value){
            state.registerStudents = value
        },
        setModal(state, value){
            state.modal = value
        },
        setModalTitle(state, value){
            state.modalTitle = value
        },
        setModalIcon(state, value){
            state.modalIcon = value
        },
        setModalMessage(state, value){
            state.modalMessage = value
        },
        setProgressOverlay(state, value){
            state.progressOverlay = value
        },
        setLessons(state, value){
            state.lessons = value
        },
        setEditedLesson(state, value){
            state.editedLesson = value
        },
        setAcademicTerm(state, value){
            state.academicTerm = value
        },
        setLessonOverlay(state, value){
            state.lessonOverlay = value
        },
        setPostLesson(state, value){
            state.postLesson = value
        },
        setAcademicYearId(state, value){
            state.academicYearId = value
        },
        setDeleteLesson(state, value){
            state.deleteLesson = value
        },
        setTeacherLessons(state, value){
            state.teacherLessons = value
        },        
        setFormTeacherAssignment(state, value){
            state.formTeacherAssignment = value
        },
        setFormClasses(state, value){
            state.formClasses = value
        },
        setSubjects(state, value){
            state.subjects = value
        },
        setFormDeanAssignments(state, value){
            state.formDeanAssignments = value
        },               
        setDialog(state, value){
            state.dialog = value
        },
        setEditedEmployee(state, value){
            state.editedEmployee = value
        }
    },

    actions: {
        async downloadRegistrationData(){
            return axios({
                method: 'get',
                url: '/api/registration-data-spreadsheet',
                responseType: 'arraybuffer',
            });
        },

        async resetPassword(state, id){
            let url = '/api/reset-password'
            return axios.post(url, {
                "student_id" : id
            });            
        },

        registerAll(){
            let url = '/api/term-reports-register'            
            return axios.post(url);
        },       

        getFormClasses(){
            let url = 'api/form-classes-list';
            return axios.get(url);
        },

        getTeacherLessons({ getters }){
            //console.log(getters.getEditedEmployee.id);
            let url = 'api/teacher-lessons-timetable/' + getters.getEditedEmployee.id;
            return axios.get(url);
        },

        postTeacherLesson({ getters }){
            let url = 'api/teacher-lesson';
            return axios.post(url, getters.getEditedLesson);
        },

        getSubjects(){
            let url = 'api/subjects';
            return axios.get(url);
        },

        setCurrentAcademicTerm({ getters }){
            let url = 'api/current-period';
            console.log(getters.getAcademicTerm);
            return axios.post(url, getters.getAcademicTerm)
        },

        getCurrentAcademicTerm(){
            let url = 'api/current-period';
            return axios.get(url);
        },

        postLesson({ getters }){
            let url = 'api/teacher-lesson';
            return axios.post(url, getters.getPostLesson);
        },

        deleteLesson({ getters }){
            let url = 'api/delete-teacher-lesson';
            console.log(getters.getDeleteLesson);
            return axios.post(url, getters.getDeleteLesson);
        },

        getFormTeacherClass({ getters }, academicYearId){
            let url = 'api/form-teacher-class/'
            + getters.getEditedEmployee.id + '/'
            + academicYearId;
            //console.log(getters.getSelectedEmployeeId);
            return axios.get(url);
        },

        postFormTeacherAssignment({ getters }){
            let url = 'api/form-teacher-class';
            return axios.post(url, getters.getFormTeacherAssignment);
        },

        getFormDeanAssignments({ getters }){
            let url = 'api/form-dean-assignments/'
            + getters.getEditedEmployee.id;
            return axios.get(url);
        },

        postFormDeanAssignments({ getters }){
            let url = 'api/form-dean-assignments';            
            return axios.post(url, getters.getFormDeanAssignments);
        },
       
    }
}    