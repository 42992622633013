<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="80vw"
    >
        <v-card
            class=""
            flat
            width="80vw"
            style="overflow:hidden"
        >
            <v-system-bar  window color="primary" dark>
                <v-spacer></v-spacer>
                <v-btn small icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-system-bar>

            <!-- <v-toolbar color="primary" dark>
                <v-toolbar-title> User Employee Listing  </v-toolbar-title>
            </v-toolbar> -->
            <v-card
                height="70vh"
                style="position:relative;" 
                flat
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%"                      
                    :src="src"
                    @load="pdfLoaded"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="overlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialogOpen: Boolean,
    },

    created () {
        this.initialize();
    },

    data: function () {
        return {
            dialog: this.dialogOpen,
            overlay: false,
            src:  this.src = process.env.VUE_APP_API_URI + "/api/user-employees",
        }
        
    },

    methods: {
       
        async initialize () {
            this.overlay = true;
            console.log('initializing...')
        },


        close () {
            this.$emit('dialog-close');
        },

        pdfLoaded () {
            this.overlay = false;
        }

    }
}
</script>

<style scoped>
    .v-system-bar--window .v-icon{
        margin-right: 0;
    }
</style>


