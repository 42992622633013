<template>
    <v-container fluid>
        <v-card
            width="800"            
            class="mx-auto pa-4"
            elevation="2"
            outlined
        >
            <v-card-title class="pt-4 px-0 pb-0">
                <h3 class="font-weight-bold">
                    Employees
                </h3>
                <v-spacer></v-spacer>
                <EmployeeNew
                    @update-employees="initialize"
                ></EmployeeNew>               
            </v-card-title>

            <div class="mb-4" v-if="displayButtons">
                <v-btn
                    :color="active.color"
                    :depressed="active.depressed"
                    class="my-2 mr-2"
                    x-small
                    tile
                    @click="filterActive()"
                >
                    <v-icon
                        left
                        light
                        v-if="activeTeachers"
                        small
                    >
                        mdi-check-circle
                    </v-icon>
                    Active
                </v-btn>
                <v-btn
                    :color="archive.color"
                    :depressed="archive.depressed"
                    class="ma-2"
                    x-small
                    tile
                    @click="filterArchive()"
                >
                    <v-icon
                        left
                        light
                        v-if="!activeTeachers"
                        small
                    >
                        mdi-archive
                    </v-icon>
                    Archive
                </v-btn>
            </div>

            <v-tabs
                v-model="tab"
                color="primary" 
                class="mt-6"               
            >
                <v-tab
                    v-for="item in subMenus"
                    :key="item"
                >
                    {{ item }}
                </v-tab>
                <v-tabs-items v-model="tab">
                    <v-tab-item>                                
                        <EmployeeEditTimetable/>
                    </v-tab-item>
                    <v-tab-item>
                        <employee-edit></employee-edit>                        
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
            <v-overlay
                absolute
                :value="overlay"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-card>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            color="primary"
        >
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    text
                    @click="snack = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar> 
    </v-container>    
</template>

<script>
import EmployeeNew from './EmployeesNew';
import EmployeeEdit from './EmployeesEdit';
import EmployeeEditTimetable from './EmployeesEditTimetable'

import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        EmployeeNew,
        EmployeeEdit,
        EmployeeEditTimetable,              
    },
    created(){
        this.initialize();
    },
    data: () => ({
        tab: 0,
        subMenus: [
            'Timetable', 'Edit'
        ],
        snack: false,
        overlay: false,
        activeTeachers: true,
        active: {
            color: 'primary',
            depressed: true
        },
        archive: {
            color: 'gray',
            depressed: false
        },
        displayButtons: false,
        
    }),
    watch: {
        getSnack: {
            handler(val){
                if(val) this.snack = val;
            }
        },
        snack: {
            handler(val){
                if(!val) this.setSnack(val);
            }
        }
    },
    computed: {
        ...mapGetters({
            getSnack: 'employees/getSnack',
            snackText: 'employees/getSnackText',
        })
    },
    methods: {
        ...mapActions({
            getEmployees: 'employees/getEmployees',
            getEmployeeStatuses: 'employees/getEmployeeStatuses', 
        }),
        
        ...mapMutations({
            setEmployees: 'employees/setEmployees',
            setEmployeeStatuses: 'employees/setEmployeeStatuses',
            setSnack: 'employees/setSnack',
        }),

        async initialize(){
            this.overlay = true;
            try {
                const employeesPromise = this.getEmployees();
                const employeeStatusPromise = this.getEmployeeStatuses();

                const { data } = await employeesPromise;
                this.setEmployees(data);

                const { data: dataStatus } = await employeeStatusPromise;
               
                // let statuses = [];
                // dataStatus.forEach(record => {
                //     if(record.id > 1) statuses.push(record);
                // });
                //console.log(statuses);
                this.setEmployeeStatuses(dataStatus);

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        filterArchive () {
            this.archive.color = 'primary';
            this.archive.depressed = false;
            this.active.color = 'gray';
            this.active.depressed = true;
            this.activeTeachers = false;
        },

        filterActive () {
            console.log('filterActive');
            this.archive.color = 'gray';
            this.archive.depressed = true;
            this.active.color = 'primary';
            this.active.depressed = false;
            this.activeTeachers = true;
        }
    }
}
</script>