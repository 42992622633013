<template>
    <v-dialog
        v-model="dialog"
        max-width="400px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="employeeStatus.status"
                dense
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
                readonly
            ></v-text-field> 
        </template>
                        
        <v-card v-if="editEmployeeStatus">
            <v-card-title>{{ item.first_name }} {{item.last_name}}</v-card-title>
            <v-card-text>                                        
                <v-select
                    v-model="employeeStatus"
                    label="Status"  
                    :items="statuses"                                          
                    item-text="status"
                    item-value="id"
                    hide-details="auto"
                    return-object
                ></v-select>
            </v-card-text>
            <v-card-actions
                class="px-6"
            >
                <v-spacer></v-spacer>
                <v-btn
                    @click="close"
                    depressed
                    class="mr-4"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="update"
                >
                    Update Status
                </v-btn>                                        
            </v-card-actions>

        </v-card>

        <v-snackbar v-model="snackbar.display" :color="snackbar.color">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-expand-transition>
            <v-card
                v-show="deleting"
                color="primary"
                dark
                class="pa-4"
            >
                <v-card-text>
                    <span class="mr-2">Updating</span> {{ item.first_name }} {{item.last_name}} status
                <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-4"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-expand-transition>


    </v-dialog> 
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        item: Object,
    },

    created: function () {
        this.initialize(); 
    },

    computed: {
        ...mapGetters({
            statuses: 'employees/getEmployeeStatuses',
        }),
    },

    data: function () {
        return {
            dialog: false,
            deleting: false,
            editEmployeeStatus: true,
            employeeStatus: {},
            snackbar: {
                display: false,
                color: 'primary',
                text: null,
            }
        }
    },

    watch: {
        dialog (val) {
            if(val){
                this.editEmployeeStatus = true;
                this.snackbar.display = false;
            }
        }
    },

    methods: {
        initialize () {
            this.employeeStatus = this.statuses.find(status => status.id === this.item.employee_status_id)
        },

        ...mapMutations({
            setDeletedEmployee: 'employees/setDeletedEmployee',
        }),

        ...mapActions({
            delete: 'employees/deleteEmployee',
        }),

        close () {
            this.dialog = false
        },

        async update () {
            this.editEmployeeStatus = false;
            this.deleting = true;
            try {
                this.setDeletedEmployee({
                    id: this.item.id,
                    employee_status_id: this.employeeStatus.id
                })
                await this.delete();
                this.$emit('update-employees')
                this.snackbar.text = `${this.item.first_name} ${this.item.last_name} status updated`
                this.snackbar.color = 'primary'
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.snackbar.text = `An error occurred ${this.item.first_name} ${this.item.last_name} status not updated`
                this.snackbar.color = 'red'
            }
            this.deleting = false;
            this.snackbar.display = true;
            setTimeout(() => {
                this.dialog = false;
                
            }, 1200)
            
        },

        
    }
}
</script>