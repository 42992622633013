<template>
    <v-card
        flat
    >
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search for teacher"
                single-line
                hide-details="auto"
                
            ></v-text-field>
        </v-card-title>    
        <v-data-table
            class="mt-10"
            :headers="headers"
            :items="employees"
            :search="search"
            fixed-header
            height="25vh"
            :loading="loading"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                    <v-btn
                        text
                        
                        outlined
                        color="primary"
                        class="mr-4"
                        @click="editEmployee(item)"
                    >
                        <v-icon
                            small
                            class="mr-2"                                       
                        >
                            mdi-pencil
                        </v-icon>
                        Edit
                    </v-btn>  
                    
                
                    <EmployeesEditStatus
                        :item="item"
                        @update-employees="updateEmployees"
                    ></EmployeesEditStatus> 
                </div>                          
            </template>
        </v-data-table>
        
        <v-dialog
            v-model="dialogEdit"
            max-width="400px"
            persistent
        >
            <v-card
                class="pa-4"
            >
                <v-card-title
                    class="pl-0"
                >Edit</v-card-title>
                <v-alert v-if="alert.show" dense text :type="alert.type">{{ alert.text }}</v-alert>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.teacher_number"
                            label="Teacher Number"
                            hide-details="auto"
                            class="mb-4"
                            
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.first_name"
                            label="First Name"
                            hide-details="auto"
                            class="mb-4"
                            
                            :rules="rules"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.last_name"
                            label="Last Name"
                            hide-details="auto"
                            class="mb-4"
                            
                            :rules="rules"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="editedEmployee.gender"
                            label="Gender"
                            hide-details="auto"
                            :items="genders"
                            class="mb-4"
                            
                        >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.user_name"
                            label="User Name"
                            hide-details="auto"
                            class="mb-4"
                            
                            :rules="rules"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="editedEmployee.email_address"
                            label="Email Address"
                            hide-details="auto"
                            class="mb-4"
                            
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        depressed
                        class="mr-4"
                        @click="closeEditTeacher"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"  
                        @click="updateEmployee"                      
                    >
                        Update
                    </v-btn>
                </v-card-actions>

                <v-overlay :value="overlay" absolute>
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.display" :color="snackbar.color">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EmployeesEditStatus from './EmployeesEditStatus.vue';
export default {
    components: {
        EmployeesEditStatus,
    },

    data: () => ({
        search: '',
        loading: false,
        editedEmployee: {},
        deletedEmployee: {},
        headers: [
            {text: 'ID', align: 'start', sortable: 'false', value: 'id', width: '100'},
            {text: 'Last Name', value: 'last_name', width: '120'},
            {text: 'First Name', value: 'first_name', width: '120'},
            {text: 'User Name', value: 'user_name', width: '120'},
            {text: '', value: 'actions', sortable: 'false', }
        ],
        dialogEdit: false,
        dialogDelete: false,
        errorMessages: [],
        confirmDelete: false,
        deleting: false,        
        status: 2,
        genders: ['M', 'F'],
        alert: {
            show: false,
            text: '',
            type: 'success'
        },
        rules: [
            value => !!value || 'Required.',
        ],
        overlay: false,
        snackbar: {
            display: false,
            color: 'primary',
            text: null,
        }
        
    }),
    watch: {
        employees: {
            handler(val){
                if(val.length != 0) this.loading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            employees: 'employees/getEmployees',
        }),
        teacherDeleted () {
            return this.deletedEmployee.first_name + ' ' + this.deletedEmployee.last_name
        }
        
    },
    methods: {
        ...mapActions({
            postEmployee: 'employees/postEmployee',            
            getEmployees: 'employees/getEmployees',            
        }),

        ...mapMutations({
            setEditedEmployee: 'employees/setEditedEmployee',
            setEmployees: 'employees/setEmployees',
            setSnack: 'employees/setSnack',
            setSnackText: 'employees/setSnackText',
        }),

        async updateEmployee () {
            this.overlay = true;
            if(
                !this.editedEmployee.first_name || 
                !this.editedEmployee.last_name ||
                !this.editedEmployee.user_name
            ){
                this.alert.show = true;
                this.alert.text = "Fields missing information";
                this.alert.type = 'error';
                this.errorMessages.push('Fields missing information');
                return;
            }

            this.setEditedEmployee(this.editedEmployee);

            try {
                await this.postEmployee();
                this.alert.text = "Employee updated";
                this.alert.type = 'success';
                this.errorMessages = [];
                this.updateEmployees();
                                               
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.alert.text = "Unable to update employee";
                this.alert.type = 'error';  

            }
            this.alert.show = true;
            this.overlay = false;

        },

        async updateEmployees(){
            try {
                const { data } = await this.getEmployees();
                this.setEmployees(data); 
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
        },

        editEmployee(item){
            console.log(item);
            this.editedEmployee = Object.assign({}, item);
            this.dialogEdit = true;
        },
        
        closeEditTeacher(){
            this.dialogEdit = false;
            this.alert.show = false;
        },
        editingStatus(field){
            switch(field){
                case 'first_name':
                    this.firstNameSaved = false;
                    this.firstNameError = false;
                    this.firstNameEditing = true;
                    break;
                case 'last_name':
                    this.lastNameSaved = false;
                    this.lastNameError = false;
                    this.lastNameEditing = true;
                    break;
                case 'email':
                    this.emailSaved = false;
                    this.emailError = false;
                    this.emailEditing = true;
                    break;
            }
        },
        editComplete(field){
            switch(field){
                case 'first_name':
                    this.firstNameSaved = true;
                    this.firstNameError = false;
                    this.firstNameEditing = false;
                    break;
                case 'last_name':
                    this.lastNameSaved = true;
                    this.lastNameError = false;
                    this.lastNameEditing = false;
                    break;
                case 'email':
                    this.emailSaved = true;
                    this.emailError = false;
                    this.emailEditing = false;
                    break;
            }
        },
        editError(field){
            switch(field){
                case 'first_name':
                    this.firstNameSaved = false;
                    this.firstNameError = true;
                    this.firstNameEditing = false;
                    break;
                case 'last_name':
                    this.lastNameSaved = false;
                    this.lastNameError = true;
                    this.lastNameEditing = false;
                    break;
                case 'email':
                    this.emailSaved = false;
                    this.emailError = true;
                    this.emailEditing = false;
                    break;
            }
        }
    },
    
}
</script>