<template>
  <div>
    <!-- Save Success -->
    
    <div v-if="saveStatus.save" class="green--text text-caption d-flex justify-center align-center">
      <span>Saved successfully! <v-icon class="ml-3" color="green" small>mdi-check-all</v-icon></span>
    </div>
    
    <!-- Saving -->
    
    <div v-else-if="saveStatus.saving" class="text-caption d-flex justify-center align-center">
      <v-progress-circular
          indeterminate
          size="15"
      ></v-progress-circular>  
      <span class="ml-3">Saving... </span>
    </div>
    

    <!-- Save Error -->
    <div v-else-if="saveStatus.error" class="red--text text-caption d-flex justify-center align-center">
      <v-icon>mdi-alert-circle</v-icon>
      <span>Error occurred! <v-icon class="ml-3" color="red" small>mdi-alert-circle</v-icon></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    saveStatus: {
      type: Object,
      required: true
    }
  }
};
</script>

