<template>
    <tr>
        <td>{{ lessonRecord.subject_id }}</td>
        <td> 
            <v-autocomplete
                v-model="lessonRecord.new_subject_id"
                :items="subjects"
                item-text="title"
                item-value="id"                
                outlined
                dense
                hide-details
                @change="updateLesson"
            ></v-autocomplete>
        </td>
        <td>
            <v-autocomplete
                v-model="selectedClasses"
                :items="formClasses"
                outlined
                dense
                hide-details
                multiple
                chips
                deletable-chips
                small-chips
                @change="updateLesson"
            ></v-autocomplete>
        </td>
        <td> 
            <v-icon
                small
                @click="deleteSelectedLesson"
                tabindex="-1"
            >
                mdi-delete
            </v-icon>
        </td>
        <td>            
            <v-progress-circular
                indeterminate
                size="16"
                width="3"
                v-show="saveStatus.saving"
            ></v-progress-circular>
            <v-icon
                small
                color="green" 
                v-show="saveStatus.saved"
                class="mr-2"
            >
                mdi-check-all
            </v-icon>
            <v-icon
                small
                color="red"
                v-show="saveStatus.saveError"
            >
                mdi-alert-circle
            </v-icon>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        lesson: Object,
        index: Number,        
    },
    created () {
        this.initialize();
    },
    data: () => ({
        subjects: [],
        formClasses: [],
        lessonRecord: {},
        saveStatus: {
            saving: false,
            saved: false,
            saveError: false,       
        },
        selectedClasses: [],     
    }),
    computed: {
        ...mapGetters({
            academicYearId : 'app/getAcademicYearId',
            teacherLessons :  'timetable/getTeacherLessons',
            lessonSubjects: 'timetable/getSubjects',
            lessonClasses: 'timetable/getFormClasses',
            editedEmployee: 'timetable/getEditedEmployee',            
        }),
    },
    watch: {
        
        subject:{
            handler (val) {
                this.lessonRecord.subject_id = val;
            }
        }
    },
    methods: {
        async initialize(){
            this.subjects = [...this.lessonSubjects];
            this.formClasses = [...this.lessonClasses];  
            this.lessonRecord = this.copyObject(this.lesson);
            if(this.lesson?.form_classes){
                this.selectedClasses = this.lesson.form_classes;
            }           
            this.lessonRecord.new_subject_id = this.lesson.subject_id;            
        },

        copyObject (obj) {
            let objCopy = {};
            for(let key in obj) {
                if(!Array.isArray(obj[key])){
                    objCopy[key] = obj[key];
                }else{
                    objCopy[key] = [...obj[key]]
                }
            }
            return objCopy;
        },

        ...mapMutations({
            setPostLesson: 'timetable/setPostLesson',
            setDeleteLesson: 'timetable/setDeleteLesson',
            setTeacherLessons: 'timetable/setTeacherLessons',            
        }),

        ...mapActions({
            postLesson: 'timetable/postLesson',
            deleteLesson: 'timetable/deleteLesson',
            displayModal: 'app/displayModal',
            getTeacherLessons: 'timetable/getTeacherLessons',            
        }),
        async updateLesson(){

            if(!this.lessonValidation()){
                return;
            }

            this.setSaveStatus();

            this.lessonRecord.form_classes = this.selectedClasses;

            this.setPostLesson(this.lessonRecord);
            
            try {
                await this.postLesson();
                this.setSaveStatus('saved');
                this.lessonRecord.subject_id = this.lessonRecord.new_subject_id;
                this.$emit(
                    'update-lessons',
                    {
                        index: this.index,
                        lesson: this.lessonRecord,
                        deleteLesson: false,
                    }
                )
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                let payload = {
                    response: error.response,
                    icon: 'error',                    
                }
                this.displayModal(payload);
                this.setSaveStatus('saveError');
            }                 
                        
        },

        lessonValidation () {
            if(
                !this.lessonRecord.new_subject_id || 
                this.selectedClasses.length == 0
            ){
                return false;
            }
            return true;
        },
        
        async deleteSelectedLesson(){ 
            this.$emit('set-overlay', true); 
            
            if(!this.lesson.subject_id && this.lesson.form_classes.length == 0){
                //empty lesson record
                this.$emit(
                    'update-lessons',
                    {
                        index: this.index,
                        deleteLesson: true,
                        lesson: null,
                    }
                )

                setTimeout(() => {
                    this.$emit('set-overlay', false);
                }, 600);

                return;
            }

            this.setDeleteLesson(this.lesson);

            try {
                await this.deleteLesson();
                this.setSaveStatus('saved');

                let teacherLessonsReduced = this.teacherLessons.filter( (lesson, index) => {                        
                    return index !== this.index;
                })

                this.setTeacherLessons([]);
                this.$nextTick(() => {
                    this.setTeacherLessons(teacherLessonsReduced);
                }) 
                
                this.$emit('set-overlay', false);

            } catch (error) {                   
                error.response ? console.log(error.response) : console.log(error);
                this.setSaveStatus('saveError');
            }
            
            
        },

        setSaveStatus(status = 'saving'){
            Object.keys(this.saveStatus).forEach(key => {
                this.saveStatus[key] = false;
                if(key == status) this.saveStatus[key] = true
            })
        }
    }
}
</script>